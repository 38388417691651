import { useState } from "react";
import Hero from "./components/Hero";
import TeamStart from "./components/TeamStart";
import TeamStart2 from "./components/TeamStart2";
import TeamStart3 from "./components/TeamStart3";

const linkStyle = {
  display: "block",
  padding: "10px",
  marginBottom: "10px",
  backgroundColor: "#000000",
  color: "#ffffff",
  borderRadius: "5px",
  textDecoration: "none",
  cursor: "pointer",
};

const containerStyle = {
  border: "none",
  padding: "5px",
};

const Team = (props) => {
  const { lang } = props;
  const [tab, setTab] = useState("Service");
  return (
    <>
      <Hero lang={lang} />
      {/* <TeamStart lang={lang} /> */}
      <div className="container-fluid p-5" style={containerStyle}>
        <div className="row">
          <div className="col-md-2">
            {/* 左侧导航条 */}
            <nav>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ marginBottom: "10px" }}>
                  <a
                    style={{
                      ...linkStyle,
                      color: tab === "Service" ? "#FB5B21" : "#ffffff",
                    }}
                    onClick={() => setTab("Service")}
                  >
                    {lang === "en" ? "Our Commitment" : "产品保证"}
                  </a>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <a
                    style={{
                      ...linkStyle,
                      color: tab === "Qc" ? "#FB5B21" : "#ffffff",
                    }}
                    onClick={() => setTab("Qc")}
                  >
                    {lang === "en" ? "Quality Control" : "质量控制"}
                  </a>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <a
                    style={{
                      ...linkStyle,
                      color: tab === "Application" ? "#FB5B21" : "#ffffff",
                    }}
                    onClick={() => setTab("Application")}
                  >
                    {lang === "en" ? "Application" : "应用"}
                  </a>
                </li>
                {/* 添加其他导航链接 */}
              </ul>
            </nav>
          </div>
          {tab === "Service" && <TeamStart lang={lang} />}
          {tab === "Qc" && <TeamStart2 lang={lang} />}
          {tab === "Application" && <TeamStart3 lang={lang} />}
        </div>
      </div>
    </>
  );
};

export default Team;
