import { useEffect, useState } from "react";

const cnInfo = [
  "公司信息",
  "香港创达电子贸易有限公司",
  "UNIT 1702A 17/F SUNBEAM PLAZA, No.1155CANTON ROAD, MONG KOK, KL",
  "联系电话",
  "陈先生",
  "Email: chan@hkgric.com",
  "Wechat: Hathcole",
  "Skype: live:.cid.fc46df9ee2172761",
];

const enInfo = [
  "Company Information",
  "HONGKONG GRAND RICH ELECTRONIC TRADE CO., LTD.",
  "UNIT 1702A 17/F SUNBEAM PLAZA, No.1155CANTON ROAD, MONG KOK, KL",
  "Contact Phone",
  "Alex",
  "Email: chan@hkgric.com",
  "Wechat: Hathcole",
  "Skype: live:.cid.fc46df9ee2172761",
];

const ContactStart = (props) => {
  const { lang } = props;
  const [langInfo, setLangInfo] = useState(cnInfo);

  useEffect(() => {
    setLangInfo(lang === "cn" ? cnInfo : enInfo);
  }, [lang]);

  return (
    <div className="container-fluid p-5">
      <div className="row g-5 mb-5">
        <div className="col-lg-6">
          <div className="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
            <h5 className="text-uppercase text-primary mb-4">{langInfo[0]}</h5>
            <p className="text-secondary mb-2">{langInfo[1]}</p>
            <p className="text-secondary mb-0">{langInfo[2]}</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
            <h5 className="text-uppercase text-primary mb-4">{langInfo[3]}</h5>
            <p className="text-secondary mb-2">{langInfo[4]}</p>
            <p className="text-secondary mb-0">{langInfo[7]}</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
            <h5 className="text-uppercase text-primary mb-4">Email</h5>
            <p className="text-secondary mb-2">{langInfo[4]}</p>
            <p className="text-secondary mb-0">{langInfo[5]}</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
            <h5 className="text-uppercase text-primary mb-4">Wechat</h5>
            <p className="text-secondary mb-2">{langInfo[4]}</p>
            <p className="text-secondary mb-0">{langInfo[6]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactStart;
