import { useEffect, useState } from "react";
import axios from "../../../_axios";

const cnInfo = [
  "请输入你要查询的产品型号",
  "原装正品",
  "品质第一",
  "快速响应",
  "可靠供应",
  "技术支持",
  "合作共赢",
  "型号",
  "品牌",
  "封装",
  "数量",
  "询价",
  "年份",
];

const enInfo = [
  "Search Part Number",
  "Original Authentic",
  "Quality First",
  "Prompt Response",
  "Reliable Supply",
  "Technical Support",
  "Win-Win Cooperation",
  "Part No",
  "Brand",
  "Package",
  "Qty",
  "Request For Quotation",
  "D/C",
];

const initPageInfo = {
  pageSize: 10,
  currentPage: 1,
  model: "",
};

const initPageData = {
  data: [],
  totalCount: 0,
  totalPage: 0,
};

const Blog = (props) => {
  const dataList = Array.from({ length: 10 });
  const { lang } = props;
  const [langInfo, setLangInfo] = useState(cnInfo);
  const [pageInfo, setPageInfo] = useState(initPageInfo);
  const [pageData, setPageData] = useState(initPageData);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setLangInfo(lang === "cn" ? cnInfo : enInfo);
  }, [lang]);

  useEffect(() => {
    searchPageData(pageInfo);
  }, []);

  const searchPageData = (pageInfo) => {
    handlePageData(pageInfo);
  };

  const selectPageData = (pageInfo) => {
    setPageInfo(pageInfo);
    handlePageData(pageInfo);
  };

  const handlePageData = (pageInfo) => {
    axios.post("/back-server/api/inventory", pageInfo).then((data) => {
      if (data.value && Array.isArray(data.value.data)) {
        setPageData(data.value);
      } else if (
        data.value &&
        Object.prototype.toString.call(data.value.data) === "[object Object]"
      ) {
        let pageData = {
          data: [data.value.data],
          totalCount: data.value.totalCount,
          totalPage: data.value.totalPage,
        };
        setPageData(pageData);
      } else {
        setPageData(data.value);
      }
    });
  };

  return (
    <div className="container-fluid p-5">
      <div className="row g-5">
        <div className="col-lg-4">
          <div className="mb-5">
            <div className="input-group">
              <input
                type="text"
                className="form-control p-3"
                placeholder={langInfo[0]}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button
                className="btn btn-primary px-4"
                onClick={() =>
                  setPageInfo(() => {
                    let pageInfo = {
                      pageSize: 10,
                      currentPage: 1,
                      model: searchValue,
                    };
                    searchPageData(pageInfo);
                    return pageInfo;
                  })
                }
              >
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="mb-5">
            <div className="bg-dark rounded p-4 ">
              <div className="d-flex overflow-hidden mb-3 bg-light">
                <a
                  
                  target="_blank"
                  style={{ marginLeft: 50 }}
                  className="d-flex align-items-center bg-light rounded-end h5 text-uppercase p-3 mb-0"
                >
                  {langInfo[1]}
                </a>
              </div>
              <div className="d-flex overflow-hidden mb-3 bg-light">
                <a
                  
                  target="_blank"
                  style={{ marginLeft: 50 }}
                  className="d-flex align-items-center bg-light rounded-end h5 text-uppercase p-3 mb-0"
                >
                  {langInfo[2]}
                </a>
              </div>
              <div className="d-flex overflow-hidden mb-3 bg-light">
                <a
                  
                  target="_blank"
                  style={{ marginLeft: 50 }}
                  className="d-flex align-items-center bg-light rounded-end h5 text-uppercase p-3 mb-0"
                >
                  {langInfo[3]}
                </a>
              </div>
              <div className="d-flex overflow-hidden mb-3 bg-light">
                <a
                
                  target="_blank"
                  style={{ marginLeft: 50 }}
                  className="d-flex align-items-center bg-light rounded-end h5 text-uppercase p-3 mb-0"
                >
                  {langInfo[4]}
                </a>
              </div>
              <div className="d-flex overflow-hidden mb-3 bg-light">
                <a
                 
                  target="_blank"
                  style={{ marginLeft: 50 }}
                  className="d-flex align-items-center bg-light rounded-end h5 text-uppercase p-3 mb-0"
                >
                  {langInfo[5]}
                </a>
              </div>
              <div className="d-flex overflow-hidden bg-light">
                <a
                  
                  target="_blank"
                  style={{ marginLeft: 50 }}
                  className="d-flex align-items-center bg-light rounded-end h5 text-uppercase p-3 mb-0"
                >
                  {langInfo[6]}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="row g-5 pt-4">
            {Array.isArray(pageData.data) ? (
              <>
                {pageData.data.map((item, index) => (
                  <div key={index} className="job-item p-4 mt-0 mb-0">
                    <div className="row g-4">
                      <div className="col-sm-12 col-md-8 d-flex align-items-center">
                        <img
                          className="flex-shrink-0 img-fluid border rounded"
                          src={
                            item.photo
                              ? `https://www.hkgric.com/back-server/sys/common/static/${item.photo}`
                              : "/static/img/img/com-logo-1.jpg"
                          }
                          alt=""
                          style={{ width: 80, height: 80 }}
                        />
                        <div className="text-start ps-4">
                          <h5 className="mb-3">{`${langInfo[7]}: ${item.model}`}</h5>
                          <span className="text-truncate me-3">{`${langInfo[8]}: ${item.brand}`}</span>
                          <span className="text-truncate me-3">{`${langInfo[9]}: ${item.fengzhuang}`}</span>
                          <span className="text-truncate me-0">{`${langInfo[10]}: ${item.quantity}`}</span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                        <div className="d-flex mb-3">
                          <a
                            className="btn btn-primary"
                            href={`RFQ`}
                            target="_blank"
                          >
                            {`${langInfo[11]}`}
                          </a>
                        </div>
                        <small className="text-truncate">
                          <i className="far fa-calendar-alt text-primary me-2"></i>
                          {`${langInfo[12]}: ${item.year}`}
                        </small>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-5">
                  <nav aria-label="Page navigation">
                    <ul hidden={true} className="pagination pagination-sm justify-content-start m-0">
                      <li
                        className={`page-item ${
                          pageInfo.currentPage === 1 && "disabled"
                        }`}
                      >
                        <a
                          className="page-link"
                          onClick={() =>
                            selectPageData({
                              pageSize: 10,
                              currentPage: 1,
                              model: pageInfo.model,
                            })
                          }
                        >
                          {lang === "cn" ? "首页" : "First page"}
                        </a>
                      </li>
                      <li
                        className={`page-item ${
                          pageInfo.currentPage - 1 <= 0 && "disabled"
                        }`}
                      >
                        <a
                          className="page-link"
                          aria-label="Previous"
                          onClick={() => {
                            if (pageInfo.currentPage - 1 >= 1) {
                              selectPageData({
                                pageSize: 10,
                                currentPage: pageInfo.currentPage - 1,
                                model: pageInfo.model,
                              });
                            }
                          }}
                        >
                          <span aria-hidden="true">
                            <i className="bi bi-arrow-left"></i>
                          </span>
                        </a>
                      </li>
                      <li
                        className={`page-item ${
                          pageInfo.currentPage + 1 > pageData.totalPage &&
                          "disabled"
                        }`}
                      >
                        <a
                          className="page-link"
                          aria-label="Next"
                          onClick={() => {
                            if (
                              pageInfo.currentPage + 1 <=
                              pageData.totalPage
                            ) {
                              selectPageData({
                                pageSize: 10,
                                currentPage: pageInfo.currentPage + 1,
                                model: pageInfo.model,
                              });
                            }
                          }}
                        >
                          <span aria-hidden="true">
                            <i className="bi bi-arrow-right"></i>
                          </span>
                        </a>
                      </li>
                      <li
                        className={`page-item ${
                          pageInfo.currentPage === pageData.totalPage &&
                          "disabled"
                        }`}
                      >
                        <a
                          className="page-link"
                          onClick={() =>
                            selectPageData({
                              pageSize: 10,
                              currentPage: pageData.totalPage,
                              model: pageInfo.model,
                            })
                          }
                        >
                          {lang === "cn" ? "尾页" : "Last page"}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-7 d-flex align-items-center justify-content-end">
                  {lang === "cn" ? (
                    <div
                      className="text-black fw-bold text-uppercase"
                      style={{ textAlign: "center", fontSize: 16 }}
                    >
                      {`共有库存信息${pageData.totalCount}条 每页10条 第${pageInfo.currentPage}页/共${pageData.totalPage}页`}
                    </div>
                  ) : (
                    <div
                      className="text-black fw-bold text-uppercase"
                      style={{ textAlign: "center", fontSize: 16 }}
                    >
                      {`Total information ${pageData.totalCount}  , Page No: ${pageInfo.currentPage} / ${pageData.totalPage} , 10 items /page`}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className="display-6 text-black mb-md-4"
                  style={{ textAlign: "center" }}
                >
                  {`We don't have the "${pageInfo.model}" model in stock.
                  Please send us an RFQ and we will provide you with a quotation.`}
                </div>
                <div className="d-flex mb-3 justify-content-center">
                  <a
                    className="btn btn-primary"
                    onClick={() => {
                      setPageInfo(() => {
                        let pageInfo = {
                          pageSize: 10,
                          currentPage: 1,
                          model: "",
                        };
                        setSearchValue("");
                        searchPageData(pageInfo);
                        return pageInfo;
                      });
                    }}
                  >
                    Return
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
