import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const cnInfo = [
  "香港创达电子贸易有限公司",
  "首页",
  "我们的优势",
  "发送询价",
  "产品目录",
  "常见问题",
  "文章",
  "关于我们",
  "联系我们",
];

const enInfo = [
  "HONGKONG GRAND RICH ELECTRONIC TRADE CO., LTD. ",
  "Home",
  "Our advantages",
  "RFQ",
  "PRODUCTS",
  "FAQ",
  "Article",
  "About US",
  "Contact US",
];

const Header = (props) => {
  const { lang, setLang } = props;
  const [langInfo, setLangInfo] = useState(cnInfo);
  const [activeItem, setActiveItem] = useState("Main");

  useEffect(() => {
    setLangInfo(lang === "cn" ? cnInfo : enInfo);
  }, [lang]);

  const navigate = useNavigate();
  const location = useLocation();

  const gotoPage = (name) => {
    navigate("/" + name);
  };

  useEffect(() => {
    let name = location.pathname.replace("/", "");
    setActiveItem(name);
  }, [location]);

  return (
    <div className="container-fluid bg-dark px-0">
      <div className="row gx-0">
        <div className="col-lg-3 bg-white d-none d-lg-block">
          <a className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
            <img
              className="img-thumbnail"
              style={{
                width: 230,
                height: "auto",
                border: 0,
                backgroundColor: "transparent",
              }}
              src="/static/img/main/LOGO.jpg"
              alt=""
            ></img>
          </a>
        </div>
        <div className="col-lg-9">
          <div className="row gx-0 bg-white d-none d-lg-flex" style={{height: 54}}>
            <div className="col-lg-7 px-5 text-start">
              <div className="h-100 d-inline-flex align-items-center py-2 me-4">
                <h4 className="mb-0 font-weight-bold">{langInfo[0]}</h4>
              </div>
            </div>
            <div
              hidden={activeItem === "ARTICLEDETAIL"}
              className="col-lg-5 px-5 text-end"
            >
            </div>
          </div>
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0 px-lg-5">
            <a className="navbar-brand d-block d-lg-none">
              <img
                className="img-thumbnail"
                style={{ width: 80, height: "auto" }}
                src=""
                alt=""
              ></img>
            </a>
            <button
              type="button"
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarCollapse"
            >
              <div className="navbar-nav mr-auto py-0">
                <a
                  className={`nav-item nav-link font-weight-bold ${
                    activeItem === "" && "active"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoPage("")}
                >
                  {langInfo[1]}
                </a>
                <a
                  className={`nav-item nav-link font-weight-bold ${
                    activeItem === "SERVICE" && "active"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoPage("SERVICE")}
                >
                  {langInfo[2]}
                </a>
                <a
                  className={`nav-item nav-link font-weight-bold ${
                    activeItem === "RFQ" && "active"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoPage("RFQ")}
                >
                  {langInfo[3]}
                </a>
                <a
                  className={`nav-item nav-link font-weight-bold ${
                    activeItem === "PRODUCTS" && "active"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoPage("PRODUCTS")}
                >
                  {langInfo[4]}
                </a>
                <a
                  className="nav-item nav-link font-weight-bold"
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoPage("FAQ")}
                >
                  {langInfo[5]}
                </a>
                <a
                  className={`nav-item nav-link font-weight-bold ${
                    activeItem === "ARTICLE" && "active"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoPage("ARTICLE")}
                >
                  {langInfo[6]}
                </a>
                <a
                  className={`nav-item nav-link font-weight-bold ${
                    activeItem === "ABOUT" && "active"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoPage("ABOUT")}
                >
                  {langInfo[7]}
                </a>
                <a
                  className={`nav-item nav-link font-weight-bold ${
                    activeItem === "CONTACT" && "active"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoPage("CONTACT")}
                >
                  {langInfo[8]}
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
