import React from "react";

const TeamStart = (props) => {
  const { lang } = props;

  return (
    <div className="col-md-10" style={{ paddingLeft: "10px" }}>
      <div className="row">
        <div className="col-md-6">
          {/* 图片和标题靠近导航条 */}
          <h2>{lang === "en" ? "" : ""}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <img
              src={
                process.env.PUBLIC_URL + "/static/img/main/产品展示/图片1.jpg"
              }
              alt={lang === "en" ? "Image 1" : "图片1"}
            />
            <p>
              <span className="font-weight-bold">
                {lang === "en" ? "Original Authentic" : "原装正品"}
              </span>
              <br />
              {lang === "en"
                ? "Our products are sourced directly from manufacturers or authorized distributors, ensuring reliable quality. We understand that quality is the cornerstone of reputation, hence we guarantee that each component you receive possesses the highest reliability and performance. Your satisfaction is our relentless pursuit, and therefore, we will continuously strive to provide you with the finest products and services."
                : "我们的产品直接从制造商或授权经销商购买，保证质量可靠。我们深知品质是信誉的基石，因此我们确保您获得的每个组件都具有最高的可靠性和性能。您的满意是我们不懈追求的目标，因此我们将不断努力，为您提供最优质的产品和服务。"}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <img
              src={
                process.env.PUBLIC_URL + "/static/img/main/产品展示/图片2.jpg"
              }
              alt={lang === "en" ? "Image 2" : "图片2"}
            />
            <p>
              <span className="font-weight-bold">
                {lang === "en" ? "Quality Control" : "质量把控"}
              </span>
              <br />
              {lang === "en"
                ? "Ensuring that products meet specifications and quality standards is our top priority. We strictly adhere to specifications and quality criteria, ensuring that the reliability and performance of our products meet expectations. Our quality control team is responsible for thorough inspections and employs advanced testing methods to verify product integrity."
                : "确保产品符合规格和质量标准是我们的首要任务。我们严格遵循规格和质量标准，确保产品的可靠性和性能符合预期。我们的质量控制团队负责进行彻底的检查，并采用先进的测试方法来验证产品的完整性。通过坚持这些标准，我们展示了对交付可靠和高质量产品的承诺。"}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <img
              src={
                process.env.PUBLIC_URL + "/static/img/main/产品展示/图片3.jpg"
              }
              alt={lang === "en" ? "Image 3" : "图片3"}
            />
            <p>
              <span className="font-weight-bold">
                {lang === "en" ? "Logistics Services" : "迅速交货"}
              </span>
              <br />
              {lang === "en"
                ? "Our logistics services guarantee timely delivery of electronic components to the customer's specified location. We prioritize punctuality and reliability, utilizing efficient planning, advanced tracking, and proactive monitoring to ensure on-time arrivals. Our dedicated team is committed to providing seamless logistics solutions for electronic component delivery."
                : "我们的物流服务保证将电子元器件准时送达客户指定地点。我们优先考虑时效性和可靠性，利用高效的规划、先进的跟踪和积极的监控，确保按时到达。我们的专业团队致力于为电子元器件的交付提供无缝的物流解决方案。"}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <img
              src={
                process.env.PUBLIC_URL + "/static/img/main/产品展示/图片4.jpg"
              }
              alt={lang === "en" ? "Image 4" : "图片4"}
            />
            <p>
              <span className="font-weight-bold">
                {lang === "en" ? "Global Procurement" : "全球采购"}
              </span>
              <br />
              {lang === "en"
                ? "With our specialized expertise in electronic components, our global procurement network spans the globe, ensuring access to the best suppliers worldwide. This enables us to secure competitive pricing advantages while maintaining the highest quality standards."
                : "凭借我们在电子元器件领域的专业知识，我们的全球采购网络遍布全球，确保能够获取全球最优质的供应商资源。这使得我们能够在保持最高质量标准的同时获得竞争性的价格优势，为我们的客户提供卓越的产品和服务。无论是小型订单还是大型批量采购，我们都致力于满足客户需求，并为其提供全方位的支持，助力其业务蓬勃发展。"}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <img
              src={
                process.env.PUBLIC_URL + "/static/img/main/产品展示/图片5.jpg"
              }
              alt={lang === "en" ? "Image 5" : "图片5"}
            />
            <p>
              <span className="font-weight-bold">
                {lang === "en" ? "After-sales Service" : "售后服务"}
              </span>
              <br />
              {lang === "en"
                ? "We are committed to providing excellent after-sales service to our customers. Our dedicated team is ready to assist you with any issues you may encounter during product use. Our goal is to ensure your satisfaction and maintain long-term partnerships."
                : "我们致力于为客户提供卓越的售后服务。无论您在产品使用过程中遇到何种问题，我们的专业团队都将竭诚为您解决。无论您需要技术支持、产品维修还是其他相关服务，我们都会迅速响应并提供有效的解决方案，以确保您的业务能够顺利进行。"}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <img
              src={
                process.env.PUBLIC_URL + "/static/img/main/产品展示/图片6.jpg"
              }
              alt={lang === "en" ? "Image 6" : "图片6"}
            />
            <p>
              <span className="font-weight-bold">
                {lang === "en" ? "Component Optimization Services" : "物料分析"}
              </span>
              <br />
              {lang === "en"
                ? "We offer streamlined inventory management and expert technical support for electronic components. With ample inventory, we optimize and analyze your Bill of Materials (BOM), suggesting cost-effective alternatives or performance enhancements. Our comprehensive technical support ensures smooth integration and operation of components in your projects."
                : "我们提供简化的库存管理和专业的技术支持。凭借丰富的库存，我们优化和分析您的物料清单（BOM），提出成本效益更高或性能更好的替代方案。我们全面的技术支持确保元器件在您的项目中平稳集成和运行。我们的专业团队具备丰富的行业经验和技术知识，能够为您提供定制化的解决方案，满足您项目的特定需求。"}
            </p>
          </div>
        </div>
        {/* 添加其他内容 */}
      </div>
    </div>
  );
};

export default TeamStart;
