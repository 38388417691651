import React from "react";

const TeamStart3 = (props) => {
  const { lang } = props;

  const contentStyle = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <div className="col-md-10" style={{ paddingLeft: "10px" }}>
      <div className="row">
        <div className="col-md-6">
          {/* 图片和标题靠近导航条 */}
          <h2>{lang === "en" ? "" : ""}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div style={contentStyle}>
            <p>
              <span className="font-weight-bold">
                {lang === "en" ? "Communication Field" : "通信领域"}
              </span>
              <br />
              {lang === "en"
                ? "RF Devices: Used in wireless communication systems such as cell phones, satellite communication, wireless LAN, etc."
                : "射频器件：用于无线通信系统，如手机、卫星通信、无线局域网等。"}
              <br />
              {lang === "en"
                ? "Optical Fiber Communication Devices: Used in optical fiber communication systems, including laser diodes, fiber modulators, etc."
                : "光纤通信器件：用于光纤通信系统，包括激光二极管、光纤调制器等。"}
              <br />
              {lang === "en"
                ? "Signal Processing Devices: Used for signal conditioning, amplification, filtering, etc., such as operational amplifiers, digital signal processors, etc."
                : "信号处理器件：用于信号调理、放大、滤波等，如集成电路中的运算放大器、数字信号处理器等。"}
            </p>
          </div>
        </div>
        <br />
        <div className="col-md-10">
          <div style={contentStyle}>
            <p>
              <span className="font-weight-bold">
                {lang === "en"
                  ? "Computer and Information Technology Field"
                  : "计算机与信息技术领域："}
              </span>
              <br />
              {lang === "en"
                ? "Microprocessors and Microcontrollers: Used in computers, embedded systems, industrial control systems, etc."
                : "微处理器和微控制器：用于计算机、嵌入式系统、工控系统等。"}
              <br />
              {lang === "en"
                ? "Storage Devices: Such as Dynamic Random Access Memory (DRAM), flash memory, etc."
                : "存储器件：如动态随机存储器（DRAM）、闪存等。"}
              <br />
              {lang === "en"
                ? "Sensors: Used for collecting environmental data, biological signals, etc., such as temperature sensors, pressure sensors, accelerometer sensors, etc."
                : "传感器：用于采集环境数据、生物信号等，如温度传感器、压力传感器、加速度传感器等。"}
            </p>
          </div>
        </div>
        <br />
        <div className="col-md-10">
          <div style={contentStyle}>
            <p>
              <span className="font-weight-bold">
                {lang === "en"
                  ? "Medical and Healthcare Field"
                  : "医疗健康领域"}
              </span>
              <br />
              {lang === "en"
                ? "Medical Sensors: Used for monitoring vital signs, diagnosing diseases, such as heart rate sensors, blood pressure sensors, etc."
                : "医疗传感器：用于监测生命体征、诊断疾病，如心率传感器、血压传感器等。"}
              <br />
              {lang === "en"
                ? "Medical Imaging Equipment: Such as X-ray machines, CT scanners, MRI machines, etc., which include various electronic components such as detectors, amplifiers, etc."
                : "医疗影像设备：如X射线机、CT扫描仪、MRI等，其中包含了多种电子元器件，如探测器、放大器等。"}
            </p>
          </div>
        </div>
        <br />
        <div className="col-md-10">
          <div style={contentStyle}>
            <p>
              <span className="font-weight-bold">
                {lang === "en"
                  ? "Automotive and Transportation Field"
                  : "汽车与交通领域"}
              </span>
              <br />
              {lang === "en"
                ? "Electronic Control Units (ECUs) for automobiles: Used for engine management, vehicle stability control, safety systems, etc."
                : "汽车电子控制单元（ECU）：用于发动机管理、车辆稳定性控制、安全系统等。"}
              <br />
              {lang === "en"
                ? "Sensor Systems: Used for vehicle monitoring and autonomous driving, such as radar, cameras, ultrasonic sensors, etc."
                : "传感器系统：用于车辆监控和自动驾驶，如雷达、摄像头、超声波传感器等。"}
              <br />
              {lang === "en"
                ? "LED Lighting: Used for vehicle lighting systems to improve energy efficiency and reliability."
                : "LED照明：用于车辆照明系统，提高能效和可靠性。"}
            </p>
          </div>
        </div>
        <br />
        <div className="col-md-10">
          <div style={contentStyle}>
            <p>
              <span className="font-weight-bold">
                {lang === "en"
                  ? "Energy and Environment Field"
                  : "能源与环境领域"}
              </span>
              <br />
              {lang === "en"
                ? "Solar Cells: Used in solar power generation systems to convert solar energy into electrical energy."
                : "太阳能电池：用于太阳能发电系统，将太阳能转化为电能。"}
              <br />
              {lang === "en"
                ? "Energy-saving Lighting Fixtures: Including LED lights and fluorescent lights, used to reduce energy consumption."
                : "节能灯具：包括LED灯和荧光灯，用于减少能源消耗。"}
              <br />
              {lang === "en"
                ? "Environmental Sensors: Used for monitoring air quality, water quality, soil conditions, etc."
                : "环境传感器：用于监测空气质量、水质、土壤条件等。"}
            </p>
          </div>
        </div>
        <br />
        <div className="col-md-10">
          <div style={contentStyle}>
            <p>
              <span className="font-weight-bold">
                {lang === "en"
                  ? "Military and Aerospace Field"
                  : "军事与航空航天领域"}
              </span>
              <br />
              {lang === "en"
                ? "Flight Control Systems: Used for aircraft and missile control systems, including navigation, autopilot, etc."
                : "飞行控制系统：用于飞机和导弹的控制系统，包括导航、自动驾驶等。"}
              <br />
              {lang === "en"
                ? "High-Frequency Radar: Used for target detection and tracking."
                : "高频雷达：用于监测和追踪目标。"}
              <br />
              {lang === "en"
                ? "Spacecraft Electronic Equipment: Including satellite communication systems, spacecraft navigation and control systems, etc."
                : "航天器电子设备：包括卫星通信系统、航天器导航和控制系统等。"}
            </p>
          </div>
        </div>
        <br />
        <div className="col-md-10">
          <div style={contentStyle}>
            <p>
              <span className="font-weight-bold">
                {lang === "en" ? "Consumer Electronics" : "家用电子产品"}
              </span>
              <br />
              {lang === "en"
                ? "Smartphones and Tablets: Integrated with various electronic components such as processors, memory, sensors, etc."
                : "智能手机和平板电脑：集成了多种电子元器件，如处理器、存储器、传感器等。"}
              <br />
              {lang === "en"
                ? "Home Appliance Controllers: Such as air conditioning controllers, washing machine controllers, etc."
                : "家用电器控制器：如空调控制器、洗衣机控制器等。"}
              <br />
              {lang === "en"
                ? "Entertainment Devices: Such as televisions, sound systems, etc."
                : "娱乐设备：如电视、音响系统等。"}
            </p>
          </div>
        </div>
        <br />
        <div className="col-md-10">
          <div style={contentStyle}>
            <p>
              <span className="font-weight-bold">
                {lang === "en"
                  ? "Industrial Automation Field"
                  : "工业自动化领域"}
              </span>
              <br />
              {lang === "en"
                ? "PLC (Programmable Logic Controller): Used for automation control systems."
                : "PLC（可编程逻辑控制器）：用于自动化控制系统。"}
              <br />
              {lang === "en"
                ? "Sensors and Actuators: Used for monitoring the production process, controlling robots and robotic arms, etc."
                : "传感器和执行器：用于监测生产过程、控制机器人和机械臂等。"}
            </p>
          </div>
        </div>
        {/* 添加其他内容 */}
      </div>
    </div>
  );
};

export default TeamStart3;
