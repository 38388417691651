import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "../_axios/index";
import DOMPurify from "dompurify"; // 引入 DOMPurify
import Hero from "./components/Hero";

const ArticleDetail = (props) => {
  const { lang } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [data, setData] = useState({});
  const [cleanContent, setCleanContent] = useState(""); // 用于存储经过 DOMPurify 处理后的内容

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [lang, id]);

  const fetchData = async () => {
    try {
      const param = {
        id: id,
        language: lang,
      };
      const response = await axios.post("/back-server/api/article", param);
      const data = response.value.data;
      setData(data[0]);
      const cleanHTML = DOMPurify.sanitize(data[0].content);
      setCleanContent(cleanHTML);
    } catch (error) {
      console.error("[查询文章列表数据出错]:", error);
    }
  };

  return (
    <>
      <Hero lang={lang} />
      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row gy-4 posts-list">
            <article className="blog-details">
              <h2 className="title">{data.title}</h2>

              <div className="meta-top" style={{ marginTop: 20 }}>
                <ul
                  style={{ display: "flex", flexDirection: "row", padding: 0 }}
                >
                  <li className="d-flex align-items-center">
                    <i className="bi bi-person"></i> <span>HKGRIC</span>{" "}
                    {/* 设置发布人为 "HKGRIC" */}
                  </li>
                  <li
                    className="d-flex align-items-center"
                    style={{ marginLeft: 20 }}
                  >
                    <i className="bi bi-clock"></i> <span>{data.category}</span>{" "}
                    {/* 显示发布时间 */}
                  </li>
                </ul>
              </div>

              <div className="content" style={{ marginTop: 40 }}>
                {/* 将 ReactQuill 改为普通的 div，并使用 dangerouslySetInnerHTML 设置 HTML */}
                <div dangerouslySetInnerHTML={{ __html: cleanContent }} />
              </div>
            </article>
          </div>
        </div>
      </section>
      {/* 返回文章首页按钮 */}
      <div className="container text-center" style={{ marginTop: "60px" }}>
        <Link
          to="/article"
          className="btn btn-primary btn-lg"
          style={{ display: "inline-block" }}
        >
          Back to Articles
        </Link>
      </div>
    </>
  );
};

export default ArticleDetail;
