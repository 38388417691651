import React from "react";

const TeamStart2 = (props) => {
  const { lang } = props;

  // 定义不同语言对应的图片路径
  const imageSrc =
    lang === "en"
      ? "/static/img/main/产品展示/图片9.jpg"
      : "/static/img/main/产品展示/图片10.jpg";
  const imageAlt = lang === "en" ? "Image 1" : "图片1";

  return (
    <div className="col-md-10" style={{ paddingLeft: "10px" }}>
      <div className="row">
        <div className="col-md-11">
          {/* 文本放在图片下方 */}
          <p>
            <span className="font-weight-bold">
              {lang === "en"
                ? "Forging Trust with Every Transaction"
                : "用每一笔交易铸就信任"}
            </span>
            <br />
            {lang === "en"
              ? "HKGRIC regards a regular and stable supply chain as the foundation of our business. We insist on conducting rigorous inspections for every batch of products entering and leaving our warehouse. All products must undergo inspections according to the company's specified procedures to ensure that the quality meets the standards. We establish long-term and stable cooperation relationships with suppliers, strictly screen products, and guarantee quality. Inspections cover aspects such as appearance, functionality, and performance to ensure that products are intact. This measure not only ensures product quality but also earns customer trust. We will continue to adhere to this principle and provide customers with high-quality products and services."
              : "香港创达电子贸易有限公司视正规稳定的供货渠道为业务基础，坚持对每批进出仓库的产品进行严格检测。所有产品必须按公司规定的程序检测，以确保质量符合标准。我们与供应商建立长期稳定合作关系，严格筛选产品，保证质量。检测包括外观、功能、性能等，以确保产品完好无损。这一举措不仅保障了产品质量，也赢得了客户信赖。"}
            <br />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {/* 图片1显示在文本下方，不做大小限制 */}
          <div className="d-flex align-items-center">
            <img
              src={process.env.PUBLIC_URL + imageSrc}
              alt={imageAlt}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
        <div className="col-md-6">
          {/* 图片2显示在文本下方，不做大小限制 */}
          <div className="d-flex align-items-center">
            <img
              src={
                process.env.PUBLIC_URL +
                "/static/img/main/产品展示/质量控制.jpg"
              }
              alt={lang === "en" ? "Image 2" : "图片2"}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
        {/* 添加其他内容 */}
        <div className="col-md-11">
          {lang === "en"
            ? "Strict quality control processes are effective means to ensure customers receive zero-defect products.  Detailed information for all inbound and outbound products will be uploaded to our company's database archive, including:"
            : "严格的质量控制流程是确保客户使用零缺陷产品的有效手段。所有进出口产品的详细资料将上载至我们公司的资料库存档，包括:"}
          <br />
          {lang === "en"
            ? "-Part number photos (in-stock, upon arrival, upon shipment)"
            : "-零件号照片（现货，到货，发货）"}
          <br />
          {lang === "en"
            ? "-Part number packaging photos (in-stock, upon arrival, upon shipment)"
            : "-零件号封装照片（现货，到货，发货）"}
          <br />
          {lang === "en"
            ? "-Customer purchase orders and delivery lists"
            : "-客户的采购订单以及本次发货清单"}
          <br />
          {lang === "en"
            ? "-Any additional requirements from the customer"
            : "-客户是否有其它需求"}
          <br />
          {lang === "en"
            ? "-Logistics information (shipment date, customer receipt date)"
            : "-物流信息（发货日期，客户收货日期）"}
          <br />
          <br />
          {lang === "en"
            ? "Storage/Packaging/Transportation Standards"
            : "储存/包装/运输标准"}
          <br />
          {lang === "en"
            ? "Electronic components, being sensitive items, must adhere to strict standards and requirements during storage, packaging, and transportation.  We strictly follow the original manufacturer's environmental standards, ensuring compliance with respective project requirements at every stage, from electrostatic protection, humidity control to temperature regulation.  We are committed to maintaining product quality to ensure customer satisfaction."
            : "电子元器件作为敏感物品，在储存、包装和运输过程中，必须遵守严格的标准和要求。我们严格遵循原厂的环保标准，确保在各个环节，从静电防护、湿度控制到恒温控制，都符合相应项目的要求。我们致力于维护商品质量，以确保客户满意。"}
        </div>
      </div>
    </div>
  );
};

export default TeamStart2;
