import { useEffect, useState } from "react";

const ProductCarousel = (props) => {
  const { lang } = props;
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsArray = [
      { image: "/static/img/图片1.jpg", text: lang === "cn" ? "芯片" : "Integrated Circuits" },
      { image: "/static/img/图片2.jpg", text: lang === "cn" ? "三极管" : "Diodes & Transistors" },
      { image: "/static/img/图片3.jpg", text: lang === "cn" ? "连接器" : "Connectors" },
      { image: "/static/img/图片4.jpg", text: lang === "cn" ? "继电器" : "Relays" },
      { image: "/static/img/图片5.jpg", text: lang === "cn" ? "电容电阻" : "Resistors & Capacitors" },
      { image: "/static/img/图片6.jpg", text: lang === "cn" ? "保险丝" : "Fuse" },
      { image: "/static/img/图片7.jpg", text: lang === "cn" ? "电感" : "Inductors" },
      { image: "/static/img/图片8.jpg", text: lang === "cn" ? "传感器" : "Sensor"}
    ];
    setProducts(productsArray);
  }, [lang]);

  return (
    <div className="container-fluid p-0 mb-5">
      <h2 className="text-center mb-4" style={{ fontSize: "80px" }}>
        {lang === "cn" ? "产品" : "PRODUCTS"}
      </h2>
      <div
        id="product-carousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {[0, 1].map((index) => (
            <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
              <div className="d-flex flex-wrap justify-content-center">
                {products.map((product, i) => (
                  <div className="p-0" key={i}>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <img
                        src={product.image}
                        alt={`Product ${i + 1}`}
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                          margin: "0",
                          padding: "0"
                        }}
                      />
                      <p style={{ fontSize: "16px" }}>{product.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;