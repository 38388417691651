import React from "react";

const CompanyInfo = (props) => {
  const { lang } = props;

  // Define the company introduction and content in Chinese and English
  const companyInfoCN = {
    companyText1: "香港创达电子贸易有限公司是一家专业的电子元器件经销商和渠道销售公司。我们提供一站式B0M采购配送和优质服务，旨在为客户提供高效优质的解决方案。 我们拥有专业的团队，包括业务团队、设计团队、研发团队、采购团队和QC团队，以及完善的售后服务，确保及时满足客户需求并提供满意的支持。自公司成立以来，我们始终坚持“原装正品、信誉第一、效率最高、服务最好、价格合理”的经营理念，凭借专业品质和敬业精神赢得了供应商和客户的信任和支持。",
    companyText2: "我们在集成电路领域拥有二十多年的经验。目前，我们拥有大量热门电子元件库存，广泛应用于民用、工业、军事和其他各个领域。我们提供来自包括INFINEON、ADI、AVAGO、INTEL、NXP、TI、MICRON、ON等大多数主流品牌的超过1.5亿件产品。我们与众多知名品牌建立了可靠的合作关系。借助丰富的集成电路经验和与知名品牌的牢固合作，我们能够提供高质量和可靠的产品和服务，满足各行业客户的需求。我们的产品因其在民用、工业、军事和其他领域的广泛应用而备受赞誉。通过与知名品牌的合作，我们已在市场上建立了强大的品牌美誉度和竞争力。",
  };

  const companyInfoEN = {
    companyText1: "HONGKONG GRAND RICH ELECTRONIC TRADE CO., LTD. is a professional electronic components distributor and channel sales company.  We provide one-stop BOM procurement, distribution, and quality services, aiming to offer efficient and high-quality solutions to our customers.  With a professional team including business, design, research and development, procurement, and QC teams, along with comprehensive after-sales service, we ensure timely satisfaction of customer needs and provide satisfactory support.  Since our establishment, we have always adhered to the business philosophy of 'original genuine products, reputation first, highest efficiency, best service, and reasonable prices', winning the trust and support of suppliers and customers with professional quality and dedication.",
    companyText2: "We have over twenty years of experience in the field of integrated circuits. Currently, we have a large inventory of popular electronic components that are widely used in civilian, industrial, military, and other sectors. We offer over 150 million products from most mainstream brands including INFINEON, ADI, AVAGO, INTEL, NXP, TI, MICRON, ON, and others. We have established reliable partnerships with numerous well-known brands. Leveraging our rich experience in integrated circuits and strong collaborations with reputable brands, we are able to provide high-quality and reliable products and services to meet the needs of customers across various industries. Our products are highly acclaimed for their extensive applications in civilian, industrial, military, and other fields. Through collaborations with renowned brands, we have built a strong brand reputation and competitiveness in the market.",
  };

  // According to the selected language, display the corresponding content
  const companyText1 = lang.toLowerCase() === "cn" ? companyInfoCN.companyText1 : companyInfoEN.companyText1;
  const companyText2 = lang.toLowerCase() === "cn" ? companyInfoCN.companyText2 : companyInfoEN.companyText2;

  return (
    <div className="container-fluid p-0 my-5">
      <div className="row g-0 mb-4">
        <div className="col-lg-6 bg-dark p-0">
          <div className="d-flex flex-column justify-content-start align-items-start p-5">
            <h1 className="display-4 text-uppercase text-light fw-bold mb-4">
              {lang.toLowerCase() === "cn" ? "公司介绍" : "Company Introduction"}
            </h1>
            <div className="text-light fs-5">{companyText1}</div>
          </div>
        </div>
        <div className="col-lg-6 bg-dark p-0">
          <img
            className="w-100"
            style={{ height: "550px", objectFit: "cover" }}
            src="/static/img/main/about.jpg" // Replace with your image path for company 1
            alt="Company 1 Image"
          />
        </div>
      </div>
      <div className="row g-0 mb-4">
        <div className="col-lg-6 bg-dark p-0">
          <img
            className="w-100"
            style={{ height: "550px", objectFit: "cover" }}
            src="/static/img/main/about2.jpg" // Replace with your image path for company 2
            alt="Company 2 Image"
          />
        </div>
        <div className="col-lg-6 bg-dark p-0">
          <div className="d-flex flex-column justify-content-start align-items-start p-5">
            <h1 className="display-4 text-uppercase text-light fw-bold mb-4">
              {lang.toLowerCase() === "cn" ? "关于我们" : "About"}
            </h1>
            <div className="text-light fs-5">{companyText2}</div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mb-0">
        <div className="col-lg-6 bg-white p-5 text-center">
          <h1 className="display-4 text-uppercase text-dark fw-bold mb-4">
            {lang.toLowerCase() === "cn" ? "公司图片" : "Company Images"}
          </h1>
          <div className="d-flex justify-content-center align-items-center">
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <img
                key={index}
                src={`/static/img/main/COMPANY/company_image_${index}.jpg`} // Modify the image path here
                alt={`公司图片${index}`} // Modify the alt attribute here
                style={{ width: "180px", height: "180px", margin: "10px" }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;