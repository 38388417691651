import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../_axios/index";
import Hero from "./components/Hero";
import "./Article.css";

const Article = (props) => {
  const { lang } = props;
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // 新增 total pages 状态
  const articlesPerPage = 6;

  useEffect(() => {
    fetchData();
  }, [lang, currentPage]);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const param = {
        pageSize: articlesPerPage,
        currentPage: currentPage,
        searchText: "",
        language: lang,
      };
      const response = await axios.post("/back-server/api/article", param);
      const data = response.value.data;
      console.log("data", data);
      const totalCount = response.value.totalCount; // 从响应中获取总数据条数
      setList(data);
      setTotalPages(Math.ceil(totalCount / articlesPerPage)); // 设置总页数
    } catch (error) {
      console.error("[解析文章列表数据出错]:", error);
    }
  };

  const gotoDetail = (item) => {
    // console.log("[item]", item);
    // navigate("/ARTICLEDETAIL", { state: { data: item } });
    navigate(`/ARTICLEDETAIL?id=${item.id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const truncateTitle = (title) => {
    let charLimit = 0;
    if (lang === "cn") {
      charLimit = 28; // 设置中文字符限制为 28
    } else if (lang === "en") {
      charLimit = 50; // 设置英文字符限制为 50
    }

    // 截取标题
    if (title.length > charLimit) {
      return title.slice(0, charLimit) + "...";
    } else {
      return title;
    }
  };

  return (
    <>
      <Hero lang={lang} />
      <div className="container articleWrap">
        <div className="posts-list">
          {list.map((item, index) => (
            <div key={index} className="post-item d-flex">
              <div className="post-img position-relative">
                <img src={item.img} className="img-fluid" alt="" />
              </div>
              <div className="post-content articleMain">
                <div className="articleMainTitleWrap">
                  <h3
                    className="post-title mb-0"
                    style={{ cursor: "pointer" }}
                    onClick={() => gotoDetail(item)}
                  >
                    {truncateTitle(item.title)}
                  </h3>
                  <div className="meta d-flex align-items-center mt-4">
                    <div className="d-flex align-items-center">
                      <i className="bi bi-person"></i>{" "}
                      <span className="ps-2">HKGRIC</span>{" "}
                    </div>
                    <span className="px-3 text-black-50">/</span>
                    <div className="d-flex align-items-center">
                      <i className="bi bi-clock"></i>{" "}
                      <span className="ps-2">{item.category}</span>{" "}
                    </div>
                  </div>
                </div>
                <div className="readmore mt-3 text-end">
                  <span
                    onClick={() => gotoDetail(item)}
                    style={{
                      marginRight: 10,
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      color: "#FB5B21",
                      cursor: "pointer",
                    }}
                  >
                    Read More
                  </span>
                  <i
                    className="bi bi-arrow-right"
                    style={{ color: "#FB5B21" }}
                  ></i>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 80 }}
        >
          <nav aria-label="Page navigation">
            <ul className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i + 1}
                  className={`page-item ${
                    currentPage === i + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Article;
